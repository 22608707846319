import { IEntityBase } from '@ms/angular-workspace/dist/core';
import { MenuItem } from 'primeng/api';

export interface AuthStateModel {
  done: boolean;
  isAuthenticated: boolean;
  products: MenuItem[];
  profile: IUserProfile | null;
}

export const CUSTOM_SUBSCRIPTION_TIER_ID = 10;

export interface ITenantDocument {
  id: number;
  blobId: number;
  fileName: string;
  isArchived: boolean;
}

export interface ITenant extends IEntityBase {
  name: string;
  id: number; // todo: figure out why this isn't recofnized from IEntityBase
  streetAddress?: string;
  streetAddress2?: string;
  phone?: { number: string };
  contact?: {
    name?: string;
    email?: string;
  };
  analyticsStates?: string[];
  subscription?: {
    name: string;
  };
  states?: string[];
  stateIds: number[];
  isInternal: boolean;
  isNational: boolean;
  isSubscriptionActive: boolean;
  isElttoSubscribed: boolean;
  isMiSubscribed: boolean;
  analyticsStateIds?: number[];
  analyticsSubscriptionTierId?: number;
  analyticsCustomSubscriptionInfoText?: string;
  documents: ITenantDocument[];
  subscriptionTierId: number;
  clientTypeId: number;
  valuTracInfo?: IValuTracInfo[];
  customSubscriptionInfoText?: string;
}

export interface IValuTracInfo {
  companyId: number;
  companyName: string;
}

export class ValuTracInfo implements IValuTracInfo {
  companyId: number;
  companyName: string;

  get displayValue(): string {
    return `${this.companyName} (${this.companyId})`;
  }

  constructor(companyId: number, companyName: string) {
    this.companyId = companyId;
    this.companyName = companyName;
  }
}

export interface IUserProfile extends IEntityBase {
  id: number;
  email: string;
  elttoRole?: IUserRole;
  elttoRoleId: number;
  miRole: IUserRole;
  miRoleId: number;
  name: {
    firstName: string;
    middleName?: string;
    lastName?: string;
    suffix?: string;
  };
  phone: {
    number: string;
    extension?: string;
  };
  tenant: ITenant;
  tenantId: string;
  statusId: number;
  status?: IUserInfoStatus;
  isAdmin?: boolean;
  isMountainSeedUser?: boolean;
  passwordExpirationDays: number;
  userType: IUserType;
  products: IProduct[];
  permissions: IPermission[];
  invitedDate?: number;
  jobTitle?: string;
}

export interface ILoginResponse {
  IsPasswordExpiresSoon?: boolean;
}

export interface IChangePasswordPayload {
  email: string;
  currentPassword: string;
  newPassword: string;
}

export interface IChangePasswordShort {
  email: string;
  newPassword: string;
}

export interface ISubscriptionTier {
  id: number;
  name: string;
  maximumNumberOfStates: number;
  maximumNumberOfUsers: number;
}

export interface IProduct {
  id: number;
  name: string;
}

export interface IUserRole {
  id: number;
  name: string;
}

export interface IUserInfoStatus {
  id: number;
  name: string;
}

export interface ITenantStatus extends IUserInfoStatus {
  isActive: boolean;
}

export interface IInquiryType {
  id: number;
  name: string;
}

export interface IUserType {
  id: number;
  name: string;
}

export interface IState {
  id: number;
  name: string;
  shortName: string;
}

export interface IClientType {
  id: number;
  name: string;
  hasOrganizationData: boolean;
}

export interface IContactUsDTO {
  name: string;
  email: string;
  inquiryType: string;
  message: string;
}

export enum UserRole {
  MI_ADMIN = 1,
  MI_USER = 2,
  ELTTO_ADMIN = 1,
  ELTTO_USER = 2,
  ELTTO_APPRAISER = 3,
}

export enum UserStatus {
  Invited = 1,
  Active = 2,
  Inactive = 3,
}

export enum Product {
  Eltto = 1,
  Mi = 2,
}

export interface IRole extends IEntityBase {
  id: number;
  name: string;
  applicableProduct: {
    id: number;
    name: string;
  };
  applicableUserType: {
    id: number;
    name: string;
  };
  permissions: IPermission[];
}

export interface IPermission {
  featureId: number;
  read: boolean;
  write: boolean;
}

export namespace AuthActions {
  export class Login {
    static readonly type = '[Auth] Login';

    constructor(public payload: { username: string; password: string }) {}
  }

  export class CheckAuth {
    static readonly type = '[Auth] CheckAuth';
  }

  export class SetApplications {
    static readonly type = '[Auth] SetApplications';

    constructor(public applications: MenuItem[]) {}
  }
}
